import React from "react";

function Contact() {
    return (
        <div className="contact text-white d-flex align-items-center" id="contact" >
            <div className="container h-100 text-center" >

                <h3 className="m-b-30">Contact me at </h3>
                <div className="contact-section d-flex justify-content-center m-b-60">
                    <a href="tel:+94772199181" target="_blank" rel="noopener noreferrer" className="d-block">
                        <img src="/assets/images/phone.png" alt="" className="contact-photo m-b-20" /><br />
                        +94 77 21 99 181
                    </a>
                    <a href="mailto:ishansasika@gmail.com" target="_blank" rel="noopener noreferrer">
                        <img src="/assets/images/email.png" alt="" className="contact-photo m-b-20" /><br />
                        ishansasika@gmail.com
                    </a>
                </div>

                <h3 className="m-b-30">Find me on </h3>
                <div className="contact-section d-flex justify-content-around">
                    <a href="https://github.com/ishansasika" target="_blank" rel="noopener noreferrer" className="d-block">
                        <img src="/assets/images/github.png" alt="" className="contact-photo m-b-20" /><br />
                        ishansasika
                    </a>
                    <a href="https://www.linkedin.com/in/ishansasika/" target="_blank" rel="noopener noreferrer">
                        <img src="/assets/images/linkedin.png" alt="" className="contact-photo m-b-20" /><br />
                        Ishan Karunaratne
                    </a>
                    <a href="https://www.facebook.com/ishansasika97" target="_blank" rel="noopener noreferrer">
                        <img src="/assets/images/facebook.png" alt="" className="contact-photo m-b-20" /><br />
                        Ishan Karunaratne
                    </a>
                    <a href="https://www.instagram.com/ishansasika/" target="_blank" rel="noopener noreferrer">
                        <img src="/assets/images/instagram.png" alt="" className="contact-photo m-b-20" /><br />
                        ishansasika
                    </a>
                    <a href="https://www.threads.net/@ishansasika" target="_blank" rel="noopener noreferrer">
                        <img src="/assets/images/threads.png" alt="" className="contact-photo color-invert m-b-20" /><br />
                        @ishansasika
                    </a>
                    <a href="https://twitter.com/ishansasika" target="_blank" rel="noopener noreferrer">
                        <img src="/assets/images/x.png" alt="" className="contact-photo  m-b-20" /><br />
                        @ishansasika
                    </a>
                </div>
            </div>
        </div>

    );
}

export default Contact;