import React from "react";
import { data } from "./data";

function Expertise() {
    return (
        <div className="expertise text-white d-flex align-items-center" id="expertise" >
            <div className="container h-100" >
                <h3 className="m-b-30">Expertise</h3>
                <div className="row align-items-center justify-content-around">
                    <div className="d-flex expertise-section" id="expertise-section">
                        {data.map((item, index) => (
                            <div className="expertise-card" key={index}>
                                <p className="lead m-b-40">{item.area}</p>
                                <div className="d-flex align-items-center justify-start flex-row flex-wrap">
                                    {item.technologies.map((technology, tIndex) => (
                                        item.technologies.length > 8 ?
                                            <p className="md w-50" key={tIndex}>{technology}</p> :
                                            <p className="md w-100" key={tIndex}>{technology}</p>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Expertise;