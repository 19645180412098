import React, { useState } from "react";

function Footer() {
  const [date] = useState(new Date());
  return (
    <div
      className="footer text-white d-flex align-items-center justify-content-center"
      id="footer"
    >
      <p className="lead overline m-b-0">
        © {date.getFullYear()}, Ishan Karunaratne. All Rights Reserved{" "}
      </p>
    </div>
  );
}

export default Footer;
