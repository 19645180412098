import React from "react";

function Page404() {
    return (
        <div className="not-found">
            <h1 className="m-b-0">404</h1>
            <h2 className="m-b-40">Not Found</h2>
            <p className="lead m-b-20">Sorry, the page you are looking for does not exist.</p>
            <a href="/" className="nav-home">
                Go to Home
                <span className="material-icons-round">home</span>
            </a>
        </div>
    );
}

export default Page404;