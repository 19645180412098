import React from "react";
import { BrowserRouter, useRoutes } from "react-router-dom";

import Home from "./components/home";
import NavBar from "./components/navbar";
import Footer from "./components/footer";
import Page404 from "./components/404";
import "./App.scss";

const AppRoutes = () => {
  let routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "*", element: <Page404 /> },
  ]);
  return routes;
};

const App = () => {
  return (
    <BrowserRouter>
      <div className="page-container">
        <NavBar />
        <div className="content-wraper">
          <AppRoutes />
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default App;
