import React from "react";
import { data } from "./data";

export default function ProjectsPage() {
  return (
    <div className="projects-page text-white">
      <div className="d-flex flex-wrap justify-content-around align-items-center">
        {data.map((item, index) => (
          <div className="project-page-card m-b-30" key={index}>
            <p className="lead">{item.title}</p>
            <p className="lg">{item.technologies}</p>
            <p className="sm m-b-40">( {item.subtitle} )</p>
            <p>{item.description}</p>
            {item.link && (
              <a
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex menu-link text-decoration-none justify-content-center align-items-center text-success"
              >
                {item.isWebsite ? "Refer Website" : "Try App"}
                <span className="material-icons-round fs-20 m-l-10">
                  open_in_new
                </span>
              </a>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
