import React, { useEffect, useRef } from "react";
import { data } from "./data";

function Work() {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const scrollStep = 0.75; // adjust the scrolling speed here
    let scrollAmount = 0;
    let scrollDirection = "right";
    let animationFrameId;

    const scroll = () => {
      if (scrollDirection === "right") {
        scrollAmount += scrollStep;
        container.scrollLeft = scrollAmount;

        // Change direction when reaching the end
        if (scrollAmount >= container.scrollWidth - container.clientWidth) {
          scrollDirection = "left";
        }
      } else {
        scrollAmount -= scrollStep;
        container.scrollLeft = scrollAmount;

        // Change direction when reaching the start
        if (scrollAmount <= 0) {
          scrollDirection = "right";
        }
      }

      animationFrameId = requestAnimationFrame(scroll);
    };

    const handleIntersection = (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        // Start scrolling when the parent div is in view
        animationFrameId = requestAnimationFrame(scroll);
      } else {
        // Stop scrolling when the parent div is out of view
        cancelAnimationFrame(animationFrameId);
      }
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    });

    observer.observe(container);

    // Clean up the observer and animation frame when the component unmounts
    return () => {
      observer.unobserve(container);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return (
    <div className="work" id="work">
      <div className="container h-100">
        <div className="row align-items-center justify-content-center h-100">
          <h3 className="m-b-40"> Work Experience</h3>
          <div className="row align-items-center justify-content-around">
            <div
              className="d-flex work-section"
              id="work-section"
              ref={containerRef}
            >
              {data.map((item, index) => (
                <div className="work-card" key={index}>
                  <p className="lead">{item.role}</p>

                  <a
                    href={item.companyWeb}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-decoration-none text-success"
                  >
                    <p className="lg" href={item.companyWeb} target="_blank">
                      {item.company}
                    </p>
                  </a>

                  <p className="sm m-b-40">( {item.year} )</p>
                  {item.work.map((work, wIndex) => (
                    <p className="md" key={wIndex}>
                      {work}
                    </p>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Work;
